'use client'
import { RiHome5Line } from "react-icons/ri";
import MobileNavbar from "./components/MobileNavbar";
import Navbar from "./components/Navbar";
import { article, home, pen } from "../../../../public/images";
import { ChartPie, Lightbulb, PencilRuler, Settings, SquarePen } from "lucide-react";
import { useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import { IoReaderOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import Modal from 'react-modal'
export default function DashboardLayout({ children }) {
     const { token } = useSelector((state) => state.auth);
     const [selected, setSelected] = useState('')
     const router = useRouter()
     const pathname = usePathname();
     useEffect(() => {
         window.scroll(0, 0);
     }, [pathname,router]);
    return (
        <>
        <div/>
        <div className="w-full">
            <Navbar />
            {token && <MobileNavbar />}
            <div className="pb-[100px] pt-[10px]  lg:mx-[90px] md:mx-[30px] mx-[5px]">
                {children}
            </div>
            {/*  */}
          {/* <div style={{filter:'drop-shadow(0px -1px 20px rgba(0, 0, 0, 0.09))'}} className=" bg-white  fixed bottom-0  md:hidden flex  justify-evenly items-center w-full  rounded-t-md">
                    <span className={`py-4 ${selected === 'settings' &&'border-[#8641BD] border-t-2' }`}>
                    <Settings onClick={() =>{ 
                        setSelected('settings')
                        router.push('/settings')
                        }} size={20}   className={`cursor-pointer hover:text-[#8641BD] ${selected === 'settings' ?'text-[#8641BD]' :'text-[#808080]'}`} />
                    </span>
                    {/* <span className={`py-4 ${selected === 'idea' && 'border-[#8641BD] border-t-2'}`}>
                    <Lightbulb onClick={() =>{ 
                        setSelected('idea')
                        router.push('/dashboard/save-idea')
                        }}  src={article} alt="home" className={`cursor-pointer hover:text-[#8641BD] ${selected === 'idea' ? 'text-[#8641BD]' :'text-[#808080]'}`}  height={20} width={20} />
                    </span>
                    <span className={`py-4 ${selected === 'analytics' && 'border-[#8641BD] border-t-2'}`}>
                    <ChartPie onClick={()=>{
                        setSelected('analytics')
                        router.push('/dashboard/analytics')
                    }} size={20}  className={`cursor-pointer hover:text-[#8641BD] ${selected === 'analytics' ? 'text-[#8641BD]':'text-[#808080]'}`}  />
                    </span>  */}
                  {/*    <span className={`py-4 ${selected === 'articles' && 'border-[#8641BD] border-t-2'}`}>
                    <IoReaderOutline onClick={() =>{ 
                        setSelected('articles')
                        router.push('/articles')
                        }}  src={article} alt="home" className={`cursor-pointer hover:text-[#8641BD] ${selected === 'articles' ? 'text-[#8641BD]' :'text-[#808080]'}`}  size={20} />
                    </span>
                    
                    <span className={`py-4 ${selected === 'home' && 'border-[#8641BD] border-t-2'}`}>
                    <RiHome5Line onClick={() =>{ 
                        setSelected('home')
                        router.push('/explore')
                        }} src={home} alt="home" className={`cursor-pointer  hover:text-[#8641BD] ${selected === 'home' ? 'text-[#8641BD]' :"text-[#808080]"}`}   size={20} />
                    </span>
           </div> */}
        </div>
        </>

    );
}